import { faAngleRight, faMinus, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { Fragment, useEffect, useState } from 'react';
import { Package } from '../../../../Classes/Package';
import { Document } from '../../../../Classes/Document';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from '../Package.module.scss';
import { Gold as GoldIcon } from '../../../../IMG/Images';
import { Loading } from '../../../../components/Loading/Loading';
import { Helmet } from 'react-helmet';
import { Section } from '../../../../Classes/Section';
import { Link } from 'gatsby';

interface Props {
  hideBanner: boolean;
  Packages?: Package[];
}

const Gold: React.FC<Props> = ({ hideBanner, Packages }) => {
  const [gold, setGold] = useState<Package>({} as Package);
  const [sections, setSections] = useState<Section[]>([] as Section[]);
  const [collapseSections, setCollapseSection] = useState<number[]>([] as number[]);

  useEffect(() => {
    GetPackage();
  }, [Packages])

  const ToggleCollapseSection = (ID: number) => {
    let sections: number[] = [];
    sections.push(ID);
    setCollapseSection(sections);
  }

  const GetPackage = async () => {
    if (Packages) {
      var g = Packages.find(x => x.Title.toLowerCase() === "gold");
      if (g) {
        let docs: Document[] = [];
        let Sections: Section[] = [];
        setGold(g);

        g.Documents.map((d: Document) => {
          d.Package = { ...g, Documents: [] };
          docs.push(d);
        })

        docs.map(d => {
          if (!Sections.find(s => s.ID === d.SectionID)) {
            d.Section.Documents = docs.filter(x => x.SectionID === d.SectionID);
            Sections.push(d.Section);
          }
        })

        Sections.sort((a, b) => b.Documents.length - a.Documents.length);

        if (Sections[0].Documents.length < 10)
          setCollapseSection([Sections[0].ID])

        setSections(Sections);
      }
    }
  }

  return (<>
    {hideBanner ? <></> : <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Gold</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Packages", Text: "Packages" },
        { To: "", Text: "Gold" }
      ]} />
    </>}

    <div className={styles.Packages}>
      {hideBanner ? <></> : <>
        <h1>Gold Package</h1>
        <p>Offering 3 levels of HR business support subscriptions; Introductory <Link to="/Packages/Bronze">Bronze</Link>, <Link to="/Packages/Silver">Silver</Link> and Signature <Link to="/Packages/Gold">Gold</Link>. Each PrimeHR package has been expertly designed with the Small business in mind, offering full flexibility to suit your needs as your business grows and your team develops.</p>
      </>}

      <div className={styles.Package}>
        <div className={styles.PackageInfo}>
          <img loading={"lazy"} src={GoldIcon} />

          <div className={styles.PackageInfoInner}>
            <h2>Gold Package</h2>
            <h4>Whats included?</h4>
            <p>Our Signature Gold is ideally suited for the growing Medium business that recognises the importance of having a more integrated HR function but isn’t at the stage of creating their own in-house HR department just yet. PrimeHR Signature Gold will help to build capacity and provide you with the expertise you need to deal with any HR related matter as they arise.</p>
            <p>The PrimeHR Signature Gold package includes our full suite of downloadable documents and toolkits that are ready for you to transform into your own company documents PLUS the added benefit of your own optional branded version of the <b>PrimeHR Portal</b> which you can use as an intranet page and document hosting site to ensure all up to date documents are accessible to others in the team too.</p>

            <div className={styles.Buttons}>
              <p>Check out our full suite of features:</p>
              <Link to={`/Packages/Compare`}>View More</Link>
            </div>
          </div>

          <div className={styles.PackageInner}>
            <div className={styles.Employees}>
              <p>Perfect for Businesses with:</p>
              <h1>
                16 to 25
                <small>Employees</small>
              </h1>
            </div>

            {
              sections.length ? <ul>
                {
                  sections.map((section: Section, index: number) => {
                    return <Fragment key={`section-${index}`}>
                      <li key={`gold-section-${index}`}><button onClick={() => ToggleCollapseSection(section.ID)}>
                        <span>{section.Title} ({section.Documents.length})</span>
                        <i><FontAwesomeIcon icon={collapseSections.includes(section.ID) ? faMinus : faPlus} /></i>
                      </button>
                      </li>
                      {section.Documents.map((document: Document, ind: number) => {
                        return <li key={`gold-section-${index}-document-${ind}`} className={collapseSections.includes(section.ID) ? styles.Expand : ""}>
                          <Link to={`/Document/${document.Title.replace(/ /g, "-")}`}>
                            <span>
                              {document.Title}
                            </span>

                            <i>
                              <FontAwesomeIcon icon={faAngleRight} />
                            </i>
                          </Link>
                        </li>
                      })}
                    </Fragment>
                  })
                }

                <li className={styles.ListButton}>
                  <p>Get all these documents, all of the Bronze and Silver packages and full access to our tools and new future features for only £{gold.Cost} per month!</p>
                  <Link to={"/Subscribe?PackageID=3&PackageLength=0&AdviceID=0&AdviceLength=0"}>Sign Up Now</Link>
                </li>
              </ul> : <div className={styles.Loading}><Loading /></div>
            }
          </div>
        </div>
      </div>

      <div className={styles.Prices}>
        <div className={styles.PricesBlock}>
          {!gold.ID ? <Loading /> : <></>}

          <h2>
            <small>1 Month</small>
            £{gold.Cost}*
            <small>Per Month</small>
          </h2>

          <ul>
            <li><p>1 Months</p><span>£{parseFloat(gold.Cost) * 1}</span></li>
            <li><p>3 Months</p><span>£{parseFloat(gold.Cost) * 3}</span></li>
            <li><p>6 Months</p><span>£{parseFloat(gold.Cost) * 6}</span></li>
            <li><p>12 Months</p><span>£{parseFloat(gold.Cost) * 12}</span></li>
          </ul>

          <Link className={styles.Button} to={`/Subscribe?PackageID=${gold.ID}&PackageLength=1&AdviceID=0&AdviceLength=0`}>Subscribe</Link>
        </div>
        <div className={styles.PricesBlock}>
          {!gold.ID ? <Loading /> : <></>}

          <h2>
            <small>3 Months</small>
            £{gold.Cost3}*
            <small>Per Month</small>
          </h2>

          <ul>
            <li><p>1 Month</p><span>£{parseFloat(gold.Cost3) * 1}</span></li>
            <li><p>3 Months</p><span>£{parseFloat(gold.Cost3) * 3}</span></li>
            <li><p>6 Months</p><span>£{parseFloat(gold.Cost3) * 6}</span></li>
            <li><p>12 Months</p><span>£{parseFloat(gold.Cost3) * 12}</span></li>
          </ul>

          <p>Save {(100 - ((parseFloat(gold.Cost3) * 12) / (parseFloat(gold.Cost) * 12) * 100)).toFixed(1)}% Per Year</p>

          <Link className={styles.Button} to={`/Subscribe?PackageID=${gold.ID}&PackageLength=3&AdviceID=0&AdviceLength=0`}>Subscribe</Link>
        </div>
        <div className={styles.PricesBlock}>
          {!gold.ID ? <Loading /> : <></>}

          <h2>
            <small>6 Months</small>
            £{gold.Cost6}*
            <small>Per Month</small>
          </h2>

          <ul>
            <li><p>1 Month</p><span>£{parseFloat(gold.Cost6) * 1}</span></li>
            <li><p>3 Months</p><span>£{parseFloat(gold.Cost6) * 3}</span></li>
            <li><p>6 Months</p><span>£{parseFloat(gold.Cost6) * 6}</span></li>
            <li><p>12 Months</p><span>£{parseFloat(gold.Cost6) * 12}</span></li>
          </ul>

          <p>Save {(100 - ((parseFloat(gold.Cost6) * 12) / (parseFloat(gold.Cost) * 12) * 100)).toFixed(1)}% Per Year</p>

          <Link className={styles.Button} to={`/Subscribe?PackageID=${gold.ID}&PackageLength=6&AdviceID=0&AdviceLength=0`}>Subscribe</Link>
        </div>
        <div className={styles.PricesBlock}>
          {!gold.ID ? <Loading /> : <></>}

          <div className={styles.Sash}>
            Best Price!
          </div>

          <h2>
            <small>12 Months</small>
            £{gold.Cost12}*
            <small>Per Month</small>
          </h2>

          <ul>
            <li><p>1 Month</p><span>£{parseFloat(gold.Cost12) * 1}</span></li>
            <li><p>3 Months</p><span>£{parseFloat(gold.Cost12) * 3}</span></li>
            <li><p>6 Months</p><span>£{parseFloat(gold.Cost12) * 6}</span></li>
            <li><p>12 Months</p><span>£{parseFloat(gold.Cost12) * 12}</span></li>
          </ul>

          <p>Save {(100 - ((parseFloat(gold.Cost12) * 12) / (parseFloat(gold.Cost) * 12) * 100)).toFixed(1)}% Per Year</p>

          <Link className={styles.Button} to={`/Subscribe?PackageID=${gold.ID}&PackageLength=12&AdviceID=0&AdviceLength=0`}>Subscribe</Link>
        </div>

        <p>* Prices do not include VAT. VAT of 20% will be added at checkout.</p>
      </div>

      {hideBanner ? <></> : <>
        <div className={styles.Terms}>
          <h4>You are guaranteed 100% compliance for any HR Audit. </h4>
          In addition to our comprehensive introductory Bronze HR Business Package we also offer our 24/7 <b>Employment Advice Line**</b> which supports any additional questions or requirements you need. With the flexibility to upgrade.

          <small>** Offered as a bolt on as an additional service with our <Link to="/Packages/Silver">Silver</Link> and <Link to="/Packages/Gold">Gold</Link> HR Business Support subscription packages. Minimum 12 months subscription. All prices are subject to the number of employees and size of business and offer unlimited call usage. <Link to="/Terms-and-Conditions">Terms and Conditions</Link> apply.</small>
        </div>
      </>}
    </div>
  </>);
};

export default Gold;
