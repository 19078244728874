// extracted by mini-css-extract-plugin
export var Button = "Package-module--Button--1a377";
export var Buttons = "Package-module--Buttons--e8715";
export var Disabled = "Package-module--Disabled--ae7b8";
export var Employees = "Package-module--Employees--79bd2";
export var Expand = "Package-module--Expand--1c355";
export var Hide = "Package-module--Hide--45eeb";
export var Highlight = "Package-module--Highlight--ff095";
export var ListButton = "Package-module--ListButton--cfd17";
export var Loading = "Package-module--Loading--8dd17";
export var Package = "Package-module--Package--8d64a";
export var PackageInfo = "Package-module--PackageInfo--d149a";
export var PackageInfoInner = "Package-module--PackageInfoInner--6fc2e";
export var PackageInner = "Package-module--PackageInner--61f70";
export var Packages = "Package-module--Packages--181b0";
export var Prices = "Package-module--Prices--8dea5";
export var PricesBlock = "Package-module--PricesBlock--a808d";
export var Sash = "Package-module--Sash--6f2f5";
export var Selected = "Package-module--Selected--036a3";
export var Silver = "Package-module--Silver--93bb2";
export var Terms = "Package-module--Terms--5e7d6";
export var loading = "Package-module--loading--96fb8";