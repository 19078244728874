import React, { } from 'react';
import Bronze from './Bronze/Bronze';
import Gold from './Gold/Gold';
import Silver from './Silver/Silver';
import * as styles from './Packages.module.scss';
import { Link } from 'gatsby';
import { Package } from '../../../Classes/Package';

interface Props {
  Packages: Package[];
}

const Packages: React.FC<Props> = ({ Packages }) => {
  return (<>
    <div className={styles.Packages}>
      <h1>Our Packages</h1>
      <p>Offering 3 levels of HR business support subscriptions; Introductory <Link to="/Packages/Bronze">Bronze</Link>, <Link to="/Packages/Silver">Silver</Link> and Signature <Link to="/Packages/Gold">Gold</Link>. Each PrimeHR package has been expertly designed with the Small business in mind, offering full flexibility to suit your needs as your business grows and your team develops.</p>
      <b>
        <Link to={`/Packages/Summary`}>View a Simpler View</Link>
      </b>

      {Packages && Packages.length ? <>
        <Bronze hideBanner={true} Packages={Packages} />
        <Silver hideBanner={true} Packages={Packages} />
        <Gold hideBanner={true} Packages={Packages} />
      </> : <></>}

      <div className={styles.Terms}>
        <h4>You are guaranteed 100% compliance for any HR Audit. </h4>
        In addition to our comprehensive introductory Bronze HR Business Package we also offer our 24/7 <b>Employment Advice Line**</b> which supports any additional questions or requirements you need. With the flexibility to upgrade.

        <small>** Offered as a bolt on as an additional service with our <Link to="/Packages/Silver">Silver</Link> and <Link to="/Packages/Gold">Gold</Link> HR Business Support subscription packages. Minimum 12 months subscription. All prices are subject to the number of employees and size of business and offer unlimited call usage. <Link to="/Terms-and-Conditions">Terms and Conditions</Link> apply.</small>
      </div>
    </div>
  </>);
};

export default Packages;
