import { faAngleRight, faMinus, faPlus, faUsers } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useEffect, useState } from 'react';
import { Package } from '../../../../Classes/Package';
import Banner from '../../../../components/Banner/Banner';
import Breadcrumb from '../../../../components/Breadcrumb/Breadcrumb';
import * as styles from '../Package.module.scss';
import { Bronze as BronzeIcon } from '../../../../IMG/Images';
import { Loading } from '../../../../components/Loading/Loading';
import { Helmet } from 'react-helmet';
import { Section } from '../../../../Classes/Section';
import { Document } from '../../../../Classes/Document';
import { Link } from 'gatsby';

interface Props {
  hideBanner: boolean;
  Packages?: Package[];
}

const Bronze: React.FC<Props> = ({ hideBanner, Packages }) => {
  const [sections, setSections] = useState<Section[]>([] as Section[]);
  const [collapseSections, setCollapseSection] = useState<number[]>([] as number[]);

  useEffect(() => {
    GetPackage();
  }, [Packages])

  const ToggleCollapseSection = (ID: number) => {
    let sections: number[] = [];
    sections.push(ID);
    setCollapseSection(sections);
  }

  const GetPackage = async () => {
    if (Packages) {
      var g = Packages.find(x => x.Title.toLowerCase() === "bronze");

      if (g) {
        let docs: Document[] = [];
        let Sections: Section[] = [];

        g.Documents.map((d: Document) => {
          d.Package = { ...g, Documents: [] };
          docs.push(d);
        })

        docs.map(d => {
          if (!Sections.find(s => s.ID === d.SectionID)) {
            d.Section.Documents = docs.filter(x => x.SectionID === d.SectionID);
            Sections.push(d.Section);
          }
        })

        Sections.sort((a, b) => b.Documents.length - a.Documents.length);

        if (Sections[0].Documents.length < 10)
          setCollapseSection([Sections[0].ID])

        setSections(Sections);
      }
    }
  }

  return (<>
    {hideBanner ? <></> : <>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Bronze</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[
        { To: "/Packages", Text: "Packages" },
        { To: "", Text: "Bronze" }
      ]} />
    </>}

    <div className={styles.Packages}>
      {hideBanner ? <></> : <>
        <h1>Bronze Package</h1>
        <p>Offering 3 levels of HR business support subscriptions; Introductory <Link to="/Packages/Bronze">Bronze</Link>, <Link to="/Packages/Silver">Silver</Link> and Signature <Link to="/Packages/Gold">Gold</Link>. Each PrimeHR package has been expertly designed with the Small business in mind, offering full flexibility to suit your needs as your business grows and your team develops.</p>
      </>}

      <div className={styles.Package}>
        <div className={styles.PackageInner}>
          <div className={styles.Employees}>
            <p>Perfect for Businesses with:</p>
            <h1>
              1 to 5
              <small>Employees</small>
            </h1>
          </div>

          {
            sections.length ? <ul>
              {
                sections.map((section: Section, index: number) => {
                  return <>
                    <li key={`bronze-section-${index}`}>
                      <button onClick={() => ToggleCollapseSection(section.ID)}>
                        <span>{section.Title} ({section.Documents.length})</span>
                        <i><FontAwesomeIcon icon={collapseSections.includes(section.ID) ? faMinus : faPlus} /></i>
                      </button>
                    </li>
                    {section.Documents.map((document: Document, ind: number) => {
                      return <li key={`bronze-section-${index}-document-${ind}`} className={collapseSections.includes(section.ID) ? styles.Expand : ""}>
                        <Link to={`/Document/${document.Title.replace(/ /g, "-")}`}>
                          <span>
                            {document.Title}
                          </span>

                          <i>
                            <FontAwesomeIcon icon={faAngleRight} />
                          </i>
                        </Link>
                      </li>
                    })}
                  </>
                })
              }

              <li className={styles.ListButton}>
                <p>No bank details needed! Create a FREE PrimeHR Portal account and start your HR journey for your small business!</p>
                <Link to={"/Register"}>Sign Up Now for FREE!</Link>
              </li>
            </ul> : <div className={styles.Loading}><Loading /></div>
          }
        </div>

        <div className={styles.PackageInfo}>
          <img loading={"lazy"} src={BronzeIcon} />

          <div className={styles.PackageInfoInner}>
            <h2>Bronze Package</h2>
            <h4>Whats included?</h4>
            <p>Our introductory Bronze package is the perfect start for any business thinking about the basic HR documents and toolkits required for recruiting your first employee through to you feeling reassured and confident of remaining compliant and up to date with ongoing changes in employment legislation.</p>
            <p>Each of our documents are fully downable and have been made easy for you to transform into your own company documents that sit perfectly as part of your company policies and procedures folders or for any Personnel file reviews. </p>

            <h4>Have you reviewed your current policies and procedures lately?</h4>
            <p>You may have had your current employment policies and procedures in place for some time now and are unsure if they are up to date and in accordance with employment legislation.</p>
            <p>Please call us now on <a href="tel: 01158372772">01158 372772</a> or email us at <a href="mailto:info@primehr.co.uk">info@primehr.co.uk</a> for us to review, help and reassure you in being legally compliant.</p>
            <div className={styles.Buttons}>
              <p>Check out our full suite of features:</p>
              <Link to={`/Packages/Compare`}>View More</Link>
            </div>
          </div>
        </div>
      </div>

      {hideBanner ? <></> : <>
        <div className={styles.Terms}>
          <h4>You are guaranteed 100% compliance for any HR Audit. </h4>
          In addition to our comprehensive introductory Bronze HR Business Package we also offer our 24/7 <b>Employment Advice Line</b>* which supports any additional questions or requirements you need. With the flexibility to upgrade.

          <small>*Offered on a pay as you need, at an hourly rate of £35 per hour or can be bolted on as an additional service with our <Link to="/Packages/Silver">Silver</Link> and <Link to="/Packages/Gold">Gold</Link> HR Business Support subscription packages. Minimum 12 months subscription. All prices are subject to the number of employees and size of business and offer unlimited call usage. <Link to="/Terms-and-Conditions">Terms and Conditions</Link> apply.</small>
        </div>
      </>}
    </div>
  </>);
};

export default Bronze;
