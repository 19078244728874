import React from 'react';
import { Package } from '../Classes/Package';
import Layout from './Layout/layout';
import Packages from '../Views/Website/Packages/Packages';
import { Helmet } from 'react-helmet';
import Breadcrumb from './Breadcrumb/Breadcrumb';
import Banner from './Banner/Banner';

const PackagesPage: React.FC = (props: any) => {
  const packages: Package[] = props.pageContext.Packages;

  return (
    <Layout>
      <Helmet htmlAttributes={{ lang: 'en' }}>
        <title>PrimeHR :: Packages</title><meta charSet='utf-8' />
      </Helmet>

      <Banner />

      <Breadcrumb Trail={[{ To: "", Text: "Packages" }]} />

      {packages ? <Packages Packages={packages} /> : <></>}
    </Layout>
  );
};

export default PackagesPage;
